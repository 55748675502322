<script setup>
import { ref } from "vue";
import AppMenuItem from "./AppMenuItem.vue";

const model = ref([
  {
    label: "Dashboards",
    icon: "pi pi-home",
    items: [
      {
        label: "Jogos",
        icon: "fa-solid fa-dice",
        to: "/",
      },
      {
        label: "Dezenas",
        icon: "fa-solid fa-hashtag",
        to: "/dezenas",
      },
      {
        label: "Concursos",
        icon: "fa-solid fa-medal",
        to: "/concursos",
      },
      {
        label: "Gerar Números",
        icon: "fa-solid fa-circle-dollar-to-slot",
        to: "/gerar",
      },
      {
        label: "Sorteios",
        icon: "fa-solid fa-award",
        to: "/sorteios",
      },
      {
        label: "Acertos",
        icon: "fa-solid fa-hand-holding-dollar",
        to: "/acertos",
      },
    ],
  },
]);
</script>

<template>
  <ul class="layout-menu">
    <template v-for="(item, i) in model" :key="item">
      <AppMenuItem v-if="!item.separator" :item="item" root :index="i" />

      <li v-else class="menu-separator"></li>
    </template>
  </ul>
</template>
<!-- 
  <i class="fa-solid fa-person"></i>
  <i class="fa-solid fa-person-walking-luggage"></i>
 -->