<template>
  <router-view />
  <Toast position="bottom-right" group="br" />
</template>

<script setup>
import { inject } from "vue";
import { useToast } from "primevue/usetoast";
const toast = useToast();

const emitter = inject("emitter");

emitter.on("toast", (value) => {
  if (value) {
    toast.add({
      severity: value.severity,
      summary: value.summary,
      detail: value.detail,
      group: "br",
      life: 6000,
    });
  }
});

</script>
