import { createRouter, createWebHashHistory, NavigationGuardNext, RouteRecordRaw } from "vue-router";
import AppLayout from "@/layout/AppLayout.vue";
import { session } from "@/helpers/localStorageHelper";
import { LocalStorageKeys } from "@/types/localStorageKey";
import { Auth } from "@/types/auth";
const data: Auth | null = session.get(LocalStorageKeys.SESSION)

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: AppLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/",
        name: "home",
        meta: {
          breadcrumb: ["Jogos"],
        },
        component: () => import("@/views/pages/Jogos.vue"),
      },
      {
				path: '/acertos',
				name: 'acertos',
				component: () => import('@/views/pages/Acertos.vue'),
			},
      {
				path: '/gerar',
				name: 'gerar',
        meta: {
          breadcrumb: ["Gerar Números"],
        },
				component: () => import('@/views/pages/Gerar.vue'),
			},
      {
				path: '/dezenas',
				name: 'dezenas',
				component: () => import('@/views/pages/Dezenas.vue'),
			},
      {
				path: '/concursos',
				name: 'concursos',
				component: () => import('@/views/pages/Concursos.vue'),
			},
      {
				path: '/sorteios',
				name: 'sorteios',
				component: () => import('@/views/pages/Sorteios.vue'),
			},
      {
				path: '/settings',
				name: 'settings',
				component: () => import('@/views/Settings.vue'),
			},
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
    beforeEnter: (to, from, next) => {
      // Redirect if user is authenticated
      if (data !== null) {
          return next('/');
      }
      // Allow route entry if user is not authenticated
      return next();
    }
  },
  {
    path: "/access",
    name: "accessDenied",
    component: () => import("@/views/AccessDenied.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notfound",
    component: () => import("@/views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

const fetchLogin = (next: NavigationGuardNext) => {
  if(data === null){
    next('/login');
  }else{
    next()
  }
}

router.beforeEach((to, from, next) => {
  if(to.meta.requiresAuth){
    fetchLogin(next)
  }else{
    next();
  }
})

export default router;
